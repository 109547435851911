<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <loader/>
      <div class="addBookingList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="7" xl="5" lg="7" sm="12" cols="12" class="my-1 py-0">
              <v-card class="card-shadow" v-if="isShow">
                <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#F9780D"
                      indeterminate
                      height="3"
                      :active="addbookingloader"
                    ></v-progress-linear>
                  </div>
                <v-form class="pt-0 px-3"  enctype="multipart/form-data" v-model="valid" ref="form">
                  <v-row class="py-4" style="padding-bottom:0 !important">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            type="tel" 
                            v-mask="'####-##-##'"
                            label="Pickup date(YYYY-MM-DD)*"
                            :rules="fieldRules"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="clearpickupdate"
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        v-model="time"
                        label="Pickup Time*"
                        type="tel" 
                        v-mask="'##:##'"
                        :rules="fieldRules"
                        required
                        list="data"
                        autocomplete="off"
                        hide-details
                      ></v-text-field>
                      <datalist id="data">
                          <option v-for="time in $store.state.times" :value="time" />
                      </datalist>
                    </v-col>

                    <v-col cols="4">
                      <v-switch
                        v-model="isDelivery"
                        hide-details
                        label="Delivery"
                      ></v-switch>
                    </v-col>

                    <v-col cols="4">
                      <v-switch
                        v-model="bulkchecked"
                        hide-details
                        label="Bulk booking"
                      ></v-switch>
                    </v-col>

                    <v-col cols="4">
                      <v-switch
                        v-model="skipdays"
                        hide-details
                        label="Skip Days"
                      ></v-switch>
                    </v-col>

                    <v-col sm="12" md="12" cols="12" v-if="bulkchecked">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-autocomplete
                            :items="['Daily', 'Weekly']"
                            label="Select frequency"
                            v-model="item.frequency"
                            :search-input.sync="searchfrequency"
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"

                        >
                          <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="enddate"
                                type="tel" 
                                v-mask="'####-##-##'"
                                label="Enddate(YYYY-MM-DD)*"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details
                                @click:clear="clearenddate"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="enddate"
                              no-title
                              scrollable
                              :min="new Date().toISOString().substr(0, 10)"
                              @input="menu3 = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="#f9780d"
                                @click="menu3 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="#f9780d"
                                @click="$refs.menu3.save(enddate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>

                        </v-col>
                      </v-row>
                    </v-col>

                    <v-row v-if="skipdays" class="mb-3 mx-2">
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipweekends"
                          label="Skip Weekends"
                          color="orange"
                          value="Weekends"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipmondays"
                          label="Skip Monday"
                          color="orange"
                          value="Monday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skiptuesdays"
                          label="Skip Tuesday"
                          color="orange"
                          value="Tuesday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipwednesdays"
                          label="Skip Wednesday"
                          color="orange"
                          value="Wednesday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipthursdays"
                          label="Skip Thursday"
                          color="orange"
                          value="Thursday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipfridays"
                          label="Skip Friday"
                          color="orange"
                          value="Friday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipsaturdays"
                          label="Skip Saturday"
                          color="orange"
                          value="Saturday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-checkbox
                          v-model="item.skipsundays"
                          label="Skip Sunday"
                          color="orange"
                          value="Sunday"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row
                      v-for="(passenger, indx) in passengers" 
                        :key="indx"
                        style="display:flex;align-items:center;justify-content:space-between"
                        class="mx-2 my-2"
                      >
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        >
                      
                            <v-autocomplete
                              v-if="passengers[indx]['isTextField'] === false"
                              :items="customers"
                              :label="Plabel"
                              v-model="passenger.id" 
                              :name="`passengers[${indx}][id]`"
                              item-text="name"
                              item-value="id"
                              :rules="fieldRules"
                              @change="(event) => updateCustomer(event,indx)"
                              hide-details
                            >
                              
                              <div
                                  slot="prepend-item"
                                  class="grey--text px-4 pt-4 newcustomer"
                                  @click="setText(indx)"
                                  >
                                <p>New Customer</p>
                              </div>
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>

                            </v-autocomplete>
                            <v-text-field
                              v-if="passengers[indx]['isTextField'] === true"
                              append-icon="mdi-selection-ellipse-arrow-inside"
                              @click:append="setText(indx)"
                              :label="Plabel"
                              v-model="passenger.name" 
                              :name="`passengers[${indx}][name]`"
                              required
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>

                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                            <v-text-field
                              label="Telephone*"
                              required
                              v-model="passenger.telephone" 
                              :name="`passengers[${indx}][telephone]`"
                                :rules="fieldRules"
                                hide-details
                              ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                            <v-text-field
                              label="Email"
                              v-model="passenger.email" 
                              :name="`passengers[${indx}][email]`"
                                hide-details
                              ></v-text-field>
                          </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addPassenger" class="mx-3">mdi-plus</v-icon>
                            <v-icon @click="removePassenger(indx)">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>

                    <v-col cols="12" class="pt-0 pb-0 mt-0 mb-0" v-if="!clientpanel">
                      <p class="mx-2" @click="clientpanel = !clientpanel" style="font-size:12px !important;color:#F9780D;text-decoration:underline;cursor:pointer">Booking for another client ?</p>
                    </v-col>

                    <v-row
                      style="display:flex;align-items:center;justify-content:space-between"
                      class="mx-2 mt-0 mb-2 py-0"
                      v-if="clientpanel"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                            <v-text-field
                              label="Client Name*"
                              v-model="item.aname" 
                              required
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <v-text-field
                            label="Client Mobile"
                            v-model="item.atelephone" 
                            hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <v-text-field
                            label="Client Email"
                            v-model="item.aemail" 
                            hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="closepanel">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>

                    <v-col cols="12" v-if="!isDelivery && organisation.hascodes != undefined && organisation.hascodes == '0'" class="pt-0 mt-0 mb-3">
                        <v-switch
                          v-model="useSubProjects"
                          hide-details
                          label="Use Sub Projects"
                          @change="onSwitchchange"
                        ></v-switch>
                      </v-col>

                    <v-row class="mb-1 mx-2" v-if="!useSubProjects && !isDelivery &&  organisation.hascodes != undefined && organisation.hascodes == '0'">

                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="item.project_id"
                          :value="item.project_id"
                          item-text="name"
                          item-value="id"
                          multiple
                          chips
                          deletable-chips
                          :search-input.sync="searchproject"
                          hide-details
                        ></v-autocomplete>
                      </v-col>

                    </v-row>

                    <v-row class="mb-1 mx-2" v-if="useSubProjects && !isDelivery &&  organisation.hascodes != undefined && organisation.hascodes == '0'">
                      
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-for="(subproject,index) in projects"
                          :key="index"
                        >
                          <v-autocomplete
                            :items="subproject.subprojects"
                            :label="`Select ${capitalize(subproject.name.toLowerCase())}`"
                            :value="item.subproject_id"
                            item-text="name"
                            item-value="id"
                            chips
                            hide-details
                            deletable-chips
                            @change="(event) => updateSubprojects(event, index, subproject.id)"
                          ></v-autocomplete>
                        </v-col>

                    </v-row>

                    <v-col sm="12" md="12" cols="12" v-if="organisation.hascodes != undefined && organisation.hascodes == '1'">
                      <v-row>
                        <v-col
                          :cols="12 / Math.min(codes.length, 4)"
                          v-for="(value, key) in codes" :key="key"
                          >
                          <v-text-field
                            :label="formatLabel(key)"
                            v-model="codes[key]" :id="key"
                            hide-details
                            :rules="organisation.mandatory == '1' || parseInt(organisation.mandatory) == 1 ? fieldRules : []"
                            :required="organisation.mandatory == '1' || parseInt(organisation.mandatory) == 1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>


                    <v-col sm="12" md="12" cols="12" class="d-flex align-center mb-1">
                      <label class="mt-1 mr-2">Location Type</label>
                      <v-radio-group
                        v-model="item.location_type"
                        row
                        mandatory
                        hide-details
                        class="mt-0 pt-0"
                      >
                        <v-radio
                          label="Map"
                          value="map"
                        ></v-radio>
                        <v-radio
                          label="Manual"
                          value="manual"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12"
                        sm="12"
                        md="12">

                        <v-row>

                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Pick-up*"
                              required
                              :rules="fieldRules"
                              v-model="item.from" 
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Pick-up*</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedFrom"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label=""
                                  required
                                  :rules="fieldRules"
                                  v-model="item.from" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Drop-off*"
                              required
                              :rules="fieldRules"
                              v-model="item.to" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Drop-off*</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedTo"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label=""
                                  required
                                  :rules="fieldRules"
                                  v-model="item.to" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-plus</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>

                    <v-col cols="12"
                        sm="12"
                        md="12"
                        v-show="hidestops"
                        >

                        <v-row>

                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Add Stop"
                              v-model="item.stop1" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Add Stop</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop1"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label=""
                                  v-model="item.stop1" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Add Stop"
                              v-model="item.stop2" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Add Stop</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop2"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label=""
                                  v-model="item.stop2" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-close</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>
                    
                    <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                      <label>Trip Type</label>
                      <v-radio-group
                        v-model="item.trip_type"
                        row
                        mandatory
                        hide-details
                      >
                        <v-radio
                          label="One Way"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Wait and Return"
                          value="2"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                      <label>Request Type</label>
                      <v-radio-group
                        v-model="item.request_type"
                        row
                        mandatory
                        hide-details
                      >
                        <v-radio
                          label="Official"
                          value="Official"
                        ></v-radio>
                        <v-radio
                          label="Private"
                          value="Private"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-autocomplete
                        :items="vehicletype"
                        label="Select vehicle type"
                        v-model="item.vehicle_type_id"
                        item-text="type"
                        item-value="id"
                        :rules="fieldRules"
                        :search-input.sync="searchvehicle"
                        hide-details
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        label="Ordered by"
                        v-model="item.orderedby"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        label="Flight details"
                        v-model="item.flight"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6" md="6"
                      >
                      <v-text-field
                        label="Purpose"
                        v-model="item.remarks"
                      ></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        depressed
                        color="primary darken-1"
                        :disabled="!valid"
                        @click="proceed"
                        v-if="item.location_type == 'map'"
                      >
                        Proceed
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary darken-1"
                        :disabled="!valid"
                        :loading="loading"
                        @click="create"
                        v-if="item.location_type == 'manual'"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-form>
              </v-card>
            </v-col>
            <v-col md="4" lg="5" sm="12" cols="12"></v-col>
          </v-row>
        </v-container>
        <!--confirm details modal -->
        <v-dialog
          v-model="confirmdialog"
          max-width="420"
          >
            <v-card
              width="420"
              class="mx-auto"
            >
              <v-card-title class="white--text pt-2">
                <div style="color:#000;font-size:1rem !important;font-weight:500 !important">
                  Booking Details
                </div>
              </v-card-title>

              <v-card-text class="mt-2">
                <v-simple-table class="requestsummary" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="white-space:nowrap;">Pickup Date:</td>
                        <td>{{ date }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Pickup Time:</td>
                        <td>{{ time }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">From:</td>
                        <td>{{ item.from }}</td>
                      </tr>
                      <tr v-if="item.stop1 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop1 }}</td>
                      </tr>
                      <tr v-if="item.stop2 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop2 }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">To:</td>
                        <td>{{ item.to }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Vehicle:</td>
                        <td>{{ rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].type.type : "N/A"  }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Distance:</td>
                        <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end)))) | currency('',2) }}Km</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Time:</td>
                        <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1Time)+parseFloat(stop2Time)+parseFloat(endTime)))) | currency('',2) }}Min</td>
                      </tr>
                      <tr v-if="rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0">
                        <td style="white-space:nowrap;padding-top:1rem !important">Total Amt:</td>
                        <td style="font-size:1rem !important;font-weight:500 !important;padding-top:1rem !important" v-if="parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate) === 0">{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)))  | currency('') }}</td>
                        <td style="font-size:1rem !important;font-weight:500 !important;padding-top:1rem !important" v-else-if="parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate) > 0">
                          <span style="font-weight:bold !important" v-if="parseInt((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)) < parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate)">{{ (parseFloat(item.trip_type)*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate)) | currency('') }}</span>
                          <span style="font-weight:bold !important" v-else>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)))  | currency('') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    color="dark darken-1"
                    @click="confirmdialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary darken-1"
                    :loading="loading"
                    :disabled="!valid"
                    @click="create"
                  >
                    Submit
                  </v-btn>
              </v-card-actions>
            </v-card>

        </v-dialog>
        <!-- end confirm details -->
        <v-dialog
            v-model="progressdialog"
            hide-overlay
            persistent
            width="300"
            >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Please wait
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
        </v-dialog>
      </div>
    </div>

</template>
<style>
.newcustomer{
  cursor: pointer;
}
</style>
<script>
import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import {ability} from '@/services/ability'
import loader from '@/components/loader'

export default {
    name:"addBookingList",
    components: { VueGooglePlaces,loader },
    data(){
        return {
          isShow: true,
          map:null,
          clientpanel:false,
          isDelivery:false,
          useSubProjects:false,
          bulkchecked:false,
          skipdays:false,
          ratechecked:false,
          hidestops:false,
          country: 'ke',
          apiKey: 'AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc',
          // apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
          searchResults: [],
          service: null,
          google:gmapApi,
          center: {lat:0, lng:0},
          fieldRules: [(v) => (!!v && String(v).trim().length > 0) || 'Field is required'],
          search:null,
          customer:null,
          valid: true,
          menu3:false,
          searchvehicle:null,
          searchproject:null,
          searchsubproject:null,
          searchfrequency:null,
          dialog: false,
          updatedialog: false,
          progressdialog: false,
          sub_projects: [],
          menu: false,
          time: "",
          menu2: false,
          date: "", 
          enddate: "",
          isTextField: false,
          selectcustomer: null, 
          totalamount: '',
          vehicle: '',
          item:{

              aname: '',
              aemail: '',
              atelephone: '',

              cdistance: 0,
              distance: "",
              to: "",
              from: "",
              fromlat: null,
              fromlong: null,
              tolat: null,
              tolong: null,

              stop1: "",
              stop1lat: null,
              stop1long: null,
              stop2: "",
              stop2lat: null,
              stop2long: null,

              trip_type: "",
              location_type: "map",
              request_type: "",
              project_id: [],
              subproject_id: "",
              name: "",
              customer_id:null,
              telephone:"",
              orderedby:"",
              pickupdate:"",
              pickuptime:"",
              vehicle_id:"",
              vehicle_type_id:"",
              flight:"",
              frequency:"",
              enddate:"",
              remarks:"",
              one_trip_rate:"",
              round_trip_rate:"",
              skipweekends: null,
              skipmondays: null,
              skiptuesdays: null,
              skipwednesdays: null,
              skipthursdays: null,
              skipfridays: null,
              skipsaturdays: null,
              skipsundays: null,
            },
            loading: false,
           disabled: false,
           orgCategory: [],
           isBusy: false,
           updateModal: null,
           chooseVehicle:[],
           chooseOtherVehicle:[],
           choosevehicledialog: false,
           confirmdialog: false,
           booking_id: "",
           driverdetails: null,
           coords: {lat: 0, lng: 0},
            labelstart: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
            labelend: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
            passengers: [],
            codes: {},
            icon: null,
            imperial: null,
            path: null,
            currentlat: null,
            currentlong: null,
            directionsService: null,
            directionsDisplay: null,
            
        }
    },
    mounted(){

      this.$store.state.loader_text = 'Add Booking'

      this.setLocationLatLng()

      if(this.customers.length == 0 || this.projects.length == 0 || this.vehicletype.length == 0){
        this.getAddBookings()
      }

      this.$store.state.stop1 = 0
      this.$store.state.stop2 = 0
      this.$store.state.end = 0

      this.passengers.push({
          organisation_id: this.organisation_id,
          id: '',
          name: '',
          telephone: '',
          email: '',
          isTextField: false
        })

      this.codes = {}
      if(this.organisation.hascodes != undefined &&this.organisation.hascodes == "1") {
        this.organisation.codes.split(",").forEach(key => {
          this.$set(this.codes, key.trim(), "");
        });
        this.item.project_id = []
        this.sub_projects = []
        this.useSubProjects = false
      }

    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

    },
    computed: {
      Plabel() {
        return !this.isDelivery ? "Passenger Name*" : "Contact Name*"
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      projects() {
        return this.$store.state.bookingprojects       
      },
      rates() {
        return this.$store.state.rates       
      },
      baseAmount() {
        return this.$store.state.base_amount       
      },
      vehicletype() {
        return this.$store.state.bookingvehicletype
      },
      customers() {
        return this.$store.state.bookingcustomers
      },
      organisation_id() {
        return this.$store.state.user.user.organisation.id
      },
      organisation() {
        return this.$store.state.user.user.organisation
      },
      distance() {
        return this.$store.state.distance
      },
      stop1() {
        return this.$store.state.stop1
      },
      stop2() {
        return this.$store.state.stop2
      },
      end() {
        return this.$store.state.end
      },
      distanceTime() {
        return this.$store.state.distanceTime
      },
      stop1Time() {
        return this.$store.state.stop1Time
      },
      stop2Time() {
        return this.$store.state.stop2Time
      },
      endTime() {
        return this.$store.state.endTime
      }
    },

    watch: {
        distance: function(newVal, oldVal) {
            this.$store.state.distance = newVal
        },
        stop1: function(newVal, oldVal) {
            this.$store.state.stop1 = newVal
        },
        stop2: function(newVal, oldVal) {
            this.$store.state.stop2 = newVal
        },
        end: function(newVal, oldVal) {
            this.$store.state.end = newVal
        }
    },

    methods:{

      formatLabel(key) {
        return key !== "" 
          ? key.trim().split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ") 
          : "";
      },

      closepanel() {
        this.clientpanel = !this.clientpanel
        if(this.clientpanel == false) {
          this.item.aname = ''
          this.item.aemail = ''
          this.item.atelephone = ''
        }
      },
      clearpickupdate() {
        this.date = ""
      },
      clearenddate() {
        this.enddate = ""
      },
      addPassenger () {
        this.passengers.push({
          organisation_id: this.organisation_id,
          id: '',
          name: '',
          telephone: '',
          email: '',
          isTextField: false
        })
      },
      removePassenger (index) {
        this.passengers.splice(index, 1);
      },
      onSwitchchange($event) {
        if($event === true || $event === 'true' || $event === 'on') {
          this.item.project_id = []
        }else {
          this.sub_projects = []
        }
      },
      capitalize(name) {
        const finalSentence = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
      },
      updateSubprojects(value, index, projectId) {

        if(value !== null) {

          const val = {
                        project_id: projectId,
                        subproject_id: value
                      }

          let index = this.sub_projects.findIndex(data => data.project_id == projectId && data.subproject_id == value);
          if (index > -1) {
            
            this.sub_projects.splice(index, 1, val);
          }else {
            this.sub_projects.unshift(val)
          }

        }else {

          let index = this.sub_projects.findIndex(data => data.project_id == projectId);
          if (index > -1) {
            this.sub_projects.splice(index, 1);
          }

        }
        
      },
      shufflestops() {
        this.hidestops = !this.hidestops
        this.item.stop1 = ""
        this.item.stop1lat = null
        this.item.stop1long = null
        this.item.stop2 = ""
        this.item.stop2lat = null
        this.item.stop2long = null

        this.$store.state.waypoints = []
      },
      setText(indx) {
        
        let find = this.passengers[indx];
        if (find) {
          const container = {};
          container.organisation_id = this.organisation_id
          container.id = ''
          container.name = ''
          container.telephone = ''
          container.email = ''
          container.isTextField = !find.isTextField
          this.passengers.splice(indx, 1, container);
        }
        let found = this.passengers[indx];

        if(found.isTextField === true) {
          this.item.telephone = ''
          this.item.customer_id = null
          this.selectcustomer = null

          if (found) {
            const container = {};
            container.organisation_id = this.organisation_id
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.name = found.name
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }

        }
        if(found.isTextField === false) {
          this.item.name = ''
          if (found) {
            const container = {};
            container.organisation_id = this.organisation_id
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.name = ''
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }
        }
      },
      updateCustomer(value,indx) {
        if(value !== null) {
          let index = this.customers.filter(item => item.id === value )

          let found = this.passengers[indx];
          if (found) {
            found.id = value
            found.name = index[0].name
            found.telephone = index[0].telephone
            found.email = index[0].email
            found.isTextField = false
          }
        }
        // this.item.telephone = index[0].telephone
        // this.item.customer_id = value
        // this.item.name = index[0].name
      },
      setLocationLatLng: function() {
        navigator.geolocation.getCurrentPosition(geolocation => {
          this.center = {
            lat: geolocation.coords.latitude,
            lng: geolocation.coords.longitude
          };

        })
      },
      directionFilter (addressData) {
        this.direction = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedFrom (addressData) {
        this.item.fromlat = addressData.latitude
        this.item.fromlong = addressData.longitude
        this.item.from = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.origin = { lat: addressData.latitude, lng: addressData.longitude }

      },
      onPlaceChangedTo (addressData) {
        this.item.tolat = addressData.latitude
        this.item.tolong = addressData.longitude
        this.item.to = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.destination = { lat: addressData.latitude, lng: addressData.longitude }

      },
      onPlaceChangedStop1 (addressData) {
        this.item.stop1lat = addressData.latitude
        this.item.stop1long = addressData.longitude

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });

        this.item.stop1 = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedStop2 (addressData) {
        this.item.stop2lat = addressData.latitude
        this.item.stop2long = addressData.longitude

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });

        this.item.stop2 = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onNoResult () {},

      getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      proceed() {

        if(this.organisation_id === parseInt(161) && this.item.project_id.length == 0) {
              this.$toast.warning('Projects are required to proceed','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.fromlat === null || this.item.fromlat === "" || this.item.tolat === null || this.item.tolat === "") {
              this.$toast.warning('Every location should be picked from google suggestions','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.stop1 !== "" && this.item.stop2 !== "") {
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), 'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), 'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 !== "" && this.item.stop2 === "") {
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long),'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 !== "") {
          this.$store.state.stop1 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long),'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 === "") {
          this.$store.state.stop1 = 0
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');
        }

        this.confirmdialog = true

      },
      create(){

          if (this.$refs.form.validate()) {

            if(this.organisation_id === parseInt(161) && this.item.project_id.length == 0) {
              this.$toast.warning('Projects are required to proceed','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('customer_id',this.item.customer_id)
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('to',this.item.to)
            fd.append('from',this.item.from)
            fd.append('enddate',this.enddate)
            fd.append('frequency',this.item.frequency)
            fd.append('distance',(parseFloat(this.stop1) + parseFloat(this.stop2) + parseFloat(this.end)))
            fd.append('fromlat',this.item.fromlat)
            fd.append('fromlong',this.item.fromlong)
            fd.append('tolat',this.item.tolat)
            fd.append('tolong',this.item.tolong)

            fd.append('stop1lat',this.item.stop1lat)
            fd.append('stop1long',this.item.stop1long)
            fd.append('stop2lat',this.item.stop2lat)
            fd.append('stop2long',this.item.stop2long)
            fd.append('stop1',this.item.stop1)
            fd.append('stop2',this.item.stop2)

            fd.append('aname',this.item.aname)
            fd.append('atelephone',this.item.atelephone)
            fd.append('aemail',this.item.aemail)

            fd.append('triptime',(parseFloat(this.item.trip_type)*((parseFloat(this.stop1Time)+parseFloat(this.stop2Time)+parseFloat(this.endTime)))))

            fd.append('orderedby',this.item.orderedby)
            fd.append('pickupdate',this.date)
            fd.append('pickuptime',this.time)
            fd.append('flight',this.item.flight)
            fd.append('remarks',this.item.remarks)
            fd.append('skipweekends',this.item.skipweekends)
            fd.append('skipmondays',this.item.skipmondays)
            fd.append('skiptuesdays',this.item.skiptuesdays)
            fd.append('skipwednesdays',this.item.skipwednesdays)
            fd.append('skipthursdays',this.item.skipthursdays)
            fd.append('skipfridays',this.item.skipfridays)
            fd.append('skipsaturdays',this.item.skipsaturdays)
            fd.append('skipsundays',this.item.skipsundays)
            fd.append('one_trip_rate',this.item.one_trip_rate)
            fd.append('round_trip_rate',this.item.round_trip_rate)
            fd.append('organisation_id',this.organisation_id)
            fd.append('vehicle_type_id',this.item.vehicle_type_id)
            fd.append('project_id',JSON.stringify(this.item.project_id))
            fd.append('subprojects',JSON.stringify(this.sub_projects))
            fd.append('passengers',JSON.stringify(this.passengers))
            fd.append('trip_type',this.item.trip_type)
            fd.append('request_type',this.item.request_type)
            fd.append('location_type',this.item.location_type)
            fd.append('useSubProjects',this.item.useSubProjects)
            fd.append('isDelivery',this.isDelivery)
            fd.append('portal',"client")
            fd.append('codes',JSON.stringify(this.codes))

            axios.post('/bookings', fd, config).then(response=>{
              this.confirmdialog = false
              if(response.data.status == parseInt(200)) {
                this.$toast.success('Booking addded successfully','Success',{position: 'topRight',timeout: 7000,})
                this.clear()
              }

              if(response.data.status == parseInt(201)) {
                this.$toast.success('Booking was successful','Success',{position: 'topRight',timeout: 7000,})
                this.clear()
              }

              this.$store.state.waypoints = []
              this.$store.state.origin = null
              this.$store.state.destination = null
              this.codes = {}
                this.item.to= ""
                this.item.from= ""
                this.item.stop1= ""
                this.item.stop2= ""
                this.item.distance= ""
                this.item.fromlat= ""
                this.item.fromlong= ""
                this.item.stop1lat= null
                this.item.stop1long= null
                this.item.stop2lat= null
                this.item.stop2long= null
                this.item.tolat= ""
                this.item.frequency= ""
                this.item.enddate= ""
                this.item.tolong= ""
                this.item.name= ""
                this.item.telephone=""
                this.item.orderedby=""
                this.item.pickupdate=""
                this.item.pickuptime=""
                this.item.vehicle_type_id=""
                this.item.request_type=""
                this.item.location_type="map"
                this.passengers = []
                this.sub_projects = []
                this.item.project_id = []
                this.item.flight=""
                this.item.remarks=""

                this.time = ""
                this.date = ""

                this.passengers.push({
                  organisation_id: this.organisation_id,
                  id: '',
                  name: '',
                  telephone: '',
                  email: '',
                  isTextField: false
                })

                if(this.clientpanel == true) {
                  this.closepanel()
                }

                this.loading = false
                this.disabled = false
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }
      },
      clear () {
        this.$refs.form.reset()
      }

    }
      
}
</script>